<template>
  <v-app>
      <Ribbon1/>
  </v-app>
</template>

<script>
// @ is an alias to /src
import Ribbon1 from '@/components/Ribbon1.vue'

export default {
  name: 'Ribbon1View',
  components: {
    Ribbon1
  }
}
</script>

